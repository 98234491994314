import React from "react";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Dashboard from "../pages/dashboard/SuperAdminDashboard";
import AdminDashboard from "../pages/admin-dashboard/AdminDashboard";
import CorpoporateAdmin from "../pages/corporate-dashboard/CorpoporateAdmin";
import CorporateAdminDashboard from "../pages/corporate-dashboard/CorporateAdminDashboard";
import HealthWorkerDashboard from "../pages/health-worker-dashboard/HealthWorkerDashboard";
import ClientDashborad from "../pages/client-dashboard/ClientDashborad";
import MmbLogin from "../pages/super-admin-login/MmbLogin"
import PhrDashboard from "../pages/admin-phr-dashboard/PhrDashboard";
import SuperAdminLandingPage from "../pages/dashboard/SuperAdminLandingPage";
import UserProfile from "../pages/admin-user-profile/UserProfile";
import { ProtectedRoute } from "../utils/ProtectedRoute";
import { useAuth } from "../authProvider";
import AllUsers from "../pages/admin-users/AllUsers";
import AllOrganisation from '../pages/admin-users/AllOrganisation'
import AllHealthworkers from "../pages/admin-users/AllHealthworkers";
import BroadcastMessage from "../pages/admin-broadcast/BroadcastMessage";

//users routes
import HomePage from "../pages/Landing-page/HomePage";
import AccountSetup from "../pages/user-account-setup/AccountSetup"
import HealthWorkerProfile from "../pages/hw-profile-setup/HealthWorkerProfile";
import ProfileCompleted from "../pages/hw-profile-setup/ProfileCompleted";
import CorporateOrgProfile from "../pages/corporate-org-profile/CorporateOrgProfile";
import Emergency from "../pages/emergency-health-info/Emergency";
import UserEmergency from "../pages/emergency-health-info/UserEmergency";
import VerifyOtp from "../pages/Landing-page/VerifyOtp"




const RoutesManager = () => {
  const {token} = useAuth

  const routesForAuthenticatedOnly = [
    {
      path: "/",
      element: <ProtectedRoute />, 
      children: [
        //Routes for Super and Sub Admins
        { path: "/dashboard", element: <SuperAdminLandingPage/>},
        { path: "/services/phr", element: <PhrDashboard/>},
        { path: "/user-profile", element: <UserProfile/>},
        { path: "/corporate-landing-page", element: <CorpoporateAdmin/>},
        { path: "/corporate-admin-dashboard", element: <CorporateAdminDashboard/>},
        { path: "/health-worker-dashboard", element: <HealthWorkerDashboard/>},
        { path: "/all-users/user-profile/:id", element: <UserProfile/>},
        { path: "/corporate-landing-page", element: <CorpoporateAdmin/>},
        { path: "/super-admin-dashboard", element: <Dashboard/>},
        { path: "/client-dashboard", element: <ClientDashborad/>},
        { path: "/admin-dashboard", element: <AdminDashboard/>},
        { path: "/broadcast", element: <BroadcastMessage/>},
        { path: "/all-users", element: <AllUsers/>},
        { path: "/all-organisation", element: <AllOrganisation/>},
        { path: "/all-HealthWorkers", element: <AllHealthworkers/>},
        { path: "/set-up", element: <AccountSetup/> },
        { path: "/user/health-worker", element: <HealthWorkerProfile/> },
        { path: "/user/health-worker/:id", element: <HealthWorkerProfile/> },
        { path: "/health-worker/completed", element: <ProfileCompleted/> },
        { path: "/user/corporate", element: <CorporateOrgProfile/> },
        { path: "/health-workers/health-worker-profile/:id", element: <UserProfile/>},
        
      ],
    },
  ];

   // Define routes accessible only to non-authenticated users
   const routesForNotAuthenticatedOnly = [
    //Routes for Admim
    { path: "/admin", element: <MmbLogin/> },
    { path: "/", element: <HomePage/> },
    { path: "/emergency", element: <Emergency/> },
    { path: "/emergency/user/:sessionId", element: <UserEmergency/> },
    { path: "/verify-otp", element: <VerifyOtp/>},
  ];

    // Combine and conditionally include routes based on authentication status
    const router = createBrowserRouter([
      ...(!token ? routesForNotAuthenticatedOnly : []),
      ...routesForAuthenticatedOnly,
    ]);

      // Provide the router configuration using RouterProvider
  return <RouterProvider router={router} />;
};

export default RoutesManager;
