import React, { useState } from 'react';
import { doctor } from '../../../constants/images';
import UserProfileHeader from './UserProfileHeader';
import HealthWorkerProfile from '../../../pages/hw-profile-setup/HealthWorkerProfile';
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';





const UserProfileSetup = () => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const { userInfo } = useSelector((state) => state.auth);
  const user_account_type = userInfo.data.account_type;


    const handleClickButton = (id) => {
    navigate(`/user/health-worker${id ? `/${id}` : ''}`, {replace: true})
    }

    const handleClickOrg = () => {
      navigate('/user/corporate', {replace: true})
      navigate(0);
      }

   

  return (
    <>
      <UserProfileHeader Breadcrumb="Registration" />
      <section className="mx-auto bg-[#F7F8FA]  pb-4 px-4 sm:px-8">
        <div className="bg-white  ">
          <div className="text-center space-y-4">
            <div className="mx-[6rem] lg:w-[475px] lg:h-[342px] lg:mx-[28rem]">
              <img className="item-center py-12" src={doctor.Lifesavers} alt="Doctor" />
            </div>
          </div>
          <div className="text-center space-y-4">
            <h1 className="text-black-700 text-center pt-[3rem] font-bold font-Roboto leading-[27.3px] text-[22px] md:text-[22px]">
              Welcome
            </h1>
            <p className="text-[#A1A5B7] text-[17.55px] max-w-xl mx-auto leading-[22px]">
              There is no data added yet.<br />
              Kickstart your registration process here
            </p>
          </div>
          <div className="mt-12 justify-center space-y-6 sm:space-x-6 sm:space-y-0 sm:flex">
          <button
          onClick={
            user_account_type === 'carer'
              ? () => setShowModal(true)
              : handleClickOrg
          }
          type="submit"
          className="text-white bg-bgbutton hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm py-4 px-16 text-center item-center dark:bg-bgbutton-600 dark:hover:bg-bgbutton-700 dark:focus:ring-bgbutton-800 font-Roboto"
        >
          Start Registration
        </button>

          </div>
        </div>
        {showModal && (
         <div className="fixed inset-0 z-10 overflow-y-auto">

         <div
             className="fixed inset-0 w-full h-full bg-black opacity-40"
             onClick={() => setShowModal(false)}>
        </div>
         <div className="flex items-center min-h-screen px-4 py-8">
             <div className="relative w-full max-w-sm p-4 mx-auto  rounded-md shadow-lg">
                 <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button   onClick={() => setShowModal(false)} type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="authentication-modal">
                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
                <span className="sr-only">Close modal</span>
            </button>
            <div className="px-6 py-6 lg:px-8 h-[300px]">
                <h3 className="mb-6 text-[15px] text-center font-medium font-Roboto text-black-600 dark:text-white">Choose a Health Worker Category.</h3>
                <p className=' mb-8 text-[13px] text-center'>Note: Independent Health Worker is for someone without any affiliation to an organisation.</p>
                <form className="space-y-6 space-x-6" action="">
                <button  onClick={() => handleClickButton(5)}  type="submit"  className="ml-14 text-white bg-bgbutton hover:bg-blue-800 focus:ring-4 
                focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2.5 text-center
                 dark:bg-bgbutton-600 dark:hover:bg-bgbutton-700 dark:focus:ring-bgbutton-800">Independent Health Worker
                 </button>
                 <button   onClick={() => handleClickButton()} type="submit" className="bg-transparent  text-bgbutton bg-blue-700 hover:bg-bgbutton focus:ring-4 
                focus:outline-none focus:ring-bgbutton-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center hover:text-white
                 dark:bg-bgbutton-600 dark:hover:bg-bgbutton-700 dark:focus:ring-bgbutton-800 border border-bgbutton ">Health Worker with an Organisation
                 </button>
                </form>
            </div>
        </div>
             </div>
         </div>
         </div>

      )}
      </section>
    </>
  );
};

export default UserProfileSetup;
