import React, { useState } from 'react';
import {useNavigate } from 'react-router-dom';
import MiniSidebar from '../../components/Admin/dashboard/MiniSidebar';
import axios from 'axios';

const BroadcastMessage = () => {
  const [loading, setLoading] = useState(false)  
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [formData, setFormData] = useState({
    title: '',
    subtitle: '',
    body: '',
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

  
 

    // Make an Axios call to send the broadcast message
    axios.post('https://stagging.mymedicalbank.com/api/v1/admin/notifications', formData)
      .then((response) => {
        if (response.status === 200){
            setShowSuccessModal(true);
            console.log('Notification sent successfully', response);
            setTimeout(() => {
                setShowSuccessModal(false);
            
              }, 3000);

                // Reset the form data after submission
            setFormData({
            title: '',
            subtitle: '',
            body: '',
          });

              navigate('/broadcast')
             
            }   
      })
      .catch((error) => {
        console.log('Error Sending Notification', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  return (
    <div>
      <MiniSidebar />
      <main className="py-28 bg-[#FFF6F4]">
        <div className="max-w-screen-xl mx-auto text-gray-600 sm:px-4 md:px-8">
          <div className="max-w-lg space-y-3 px-4 sm:mx-auto sm:text-center sm:px-0">
            <p className="text-gray text-3xl font-semibold sm:text-4xl">
              Send a Broadcast Message
            </p>
          </div>
          <div className="mt-12 mx-auto px-4 p-8 bg-white sm:max-w-[48rem] sm:px-8 sm:rounded-xl">
            <form onSubmit={handleSubmit} className="space-y-5">
              <FormField
                name="title"
                label="Title "
                type="text"
                placeholder="placeholder"
                value={formData.title}
                onChange={handleChange}
              />
              <FormField
                name="subtitle"
                label="Sub Title"
                type="text"
                value={formData.subtitle}
                onChange={handleChange}
              />
              <FormField
                name="body"
                label="Message Body"
                type="textarea"
                required
                value={formData.body}
                onChange={handleChange}
              />
                {loading ? 
                <button type="submit" className="w-[150px] h-[46px] leading-6 mx-[15rem] text-white bg-bgloginbutton hover:bg-bg-bgloginbutton/90 
                    rounded-3xl text-[16px] leading-16 font-semibold font-Poppins px-14 py-2.5 text-center inline-flex items-center 
                    mr-2 mb-2" disabled={loading}>  
                      
                        <svg aria-hidden="true" className="inline w-8 h-8  text-gray-200 animate-spin dark:text-gray-600 fill-red-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg> 
                  </button>
                        : 
                        <button type="submit" className="w-[150px] h-[46px] leading-6 mx-[15rem] text-white bg-bgloginbutton hover:bg-bg-bgloginbutton/90 
                        rounded-3xl text-[16px] leading-16 font-semibold font-Poppins px-6 py-2.5 text-center inline-flex items-center 
                        mr-2 mb-2" disabled={loading}> submit 
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-6 pl-2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                        </svg>
                        </button>
                        }

           
            </form>

            {showSuccessModal && (
                    <div className="mt-12 mx-4 px-4 rounded-md border-l-4 border-green-500 bg-green-50 md:max-w-2xl md:mx-auto md:px-8">
                      <div className="flex justify-between py-3">
                      <div className="flex">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 rounded-full text-green-500" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                        </svg>
                    </div>
                    <div className="self-center ml-3">
                        <span className="text-green-600 font-semibold">
                            Success
                        </span>
                        <p className="text-green-600 mt-1">
                        Notification created successfully
                        </p>
                    </div>
                </div>
                          <button className="self-start text-green-500" onClick={() =>  setShowSuccessModal(false)}>
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                  <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                              </svg>
                          </button >
                      </div>
                  </div>
                    )}
            
          </div>
        </div>
      </main>
    </div>
    
  );
};

const FormField = ({placeholder, name, label, type, value,  onChange }) => {
  return (
    <div>
      <label htmlFor={name} className="font-medium font-Poppins">
        {label}
      </label>
      {type === 'textarea' ? (
        <textarea
          name={name}
          value={value}
          onChange={onChange}
          className="w-full mt-2 h-36 px-3 py-2 resize-none appearance-none bg-transparent outline-none border focus:border-gray-800 shadow-sm rounded-lg"
        />
      ) : (
        <input
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-gray-800 shadow-sm rounded-lg"
        />
      )}
    </div>
  );
};

export default BroadcastMessage;
