import React, { useEffect, useState } from 'react';
import FormField from '../../FormField';
import { formIcons } from '../../../constants/icons';
import { useDispatch, useSelector } from 'react-redux';
import { registerUser } from '../../../services/redux/auth/authAction/authAction';
import { useNavigate } from 'react-router-dom';
import Error from '../../global/Error';

const Register = ({ setShowModal }) => {
  //password eye icon 
  const [passwordVisible, setPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const { loading, userInfo, error, success } = useSelector(
    (state) => state.auth
    );

    console.log(success, success.status, userInfo)

  const [formData, setFormData] = useState({
    account_type: 'corporate',
    email: '',
    password: '',
    cpassword: '',
  });

  const { account_type, email, password, cpassword } = formData;

  const [errors, setErrors] = useState({}); // State for error messages

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const successMessage = success; // Set success message
  const errorMessage = error; // Set error message

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    const errors = {};

    if (!email) {
      errors.email = 'Email is required';
    }
    if (password.length < 6) {
      errors.password = 'Password must be at least 6 characters';
    }
    if (password !== cpassword) {
      errors.cpassword = 'Passwords do not match';
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const dispatch = useDispatch();

  const navigate = useNavigate()
 


const submitForm = async (e) => {
  e.preventDefault();

  if (validateForm()) {
    const data = {
      account_type,
      email: email.toLowerCase(),
      password,
    };

    try {
      // Dispatch the action and wait for the response
      await dispatch(registerUser(data));

    } catch (error) {
      // If there is an error, show error modal
      setShowErrorModal(true);
    }
  } 
      // Check if registration was successful
      if (success) {
        // Navigate to "/dashboard" or any other desired path
        navigate('/verify-otp', { state: { email: email.toLowerCase() } });
      }
};


    

  





  const closeSuccessModal = () => {
    setShowSuccessModal(false);
  };

  const closeErrorModal = () => {
    setShowErrorModal(false);
  };

  return (
    <div className="justify-center mt-12 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-[3rem] mx-auto max-w-3xl">
          <main className="h-[728px] flex flex-col items-center sm:px-4">
            <div className="space-y-6 text-gray-600 sm:max-w-md">
              <div className="bg-white rounded-[20px] shadow-2xl p-4 sm:px-[3.5rem] sm:rounded-2xl w-[357px] lg:w-[457px] ">
                <div className="text-center">
                  <div className="mt-5 space-y-2">
                    <h3 className="text-gray-800 text-[23px] font-Roboto font-bold sm:text-[30px] leading-[39.5px]">
                      Start your registration
                    </h3>
                    {/* <p className="text-black font-Roboto leading-[16.8px] text-[16px]">
                      Already have an account?{' '}
                      <a href="javascript:void(0)" className="font-medium text-[#47A7FD] hover:text-indigo-500">
                        Log in
                      </a>
                    </p> */}
                  </div>
                </div>
                <form onSubmit={submitForm} className="space-y-5">
                {error && <Error message={error}>{error}</Error>}
                  <div className="pt-12">
                    {/* <label className="font-medium">
                      <span className="flex px-2 text-black">Who would you be registering as?</span>
                    </label>
                    <ul className="items-center w-full bg-[#F3F3F3] my-2 text-sm font-medium text-gray-900 border border-gray-200 rounded-lg sm:flex dark-bg-gray-700 dark-border-gray-600 dark-text-white">
                      <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark-border-gray-600">
                        <div className="flex items-center pl-2 bg-[#F3F3F3]">
                          <FormField
                            id="horizontal-list-radio-license"
                            type="radio"
                            value="carer"
                            name="account_type"
                            onChange={handleChange}
                            checked={account_type === 'carer'}
                            className="w-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark-focus:ring-blue-600 dark-ring-offset-gray-700 dark-focus-ring-offset-gray-700 focus-ring-2 dark-bg-gray-600 dark-border-gray-500"
                          />
                          <label for="horizontal-list-radio-license" className="w-full py-3 ml-2 text-[13px] font-medium text-gray-900 dark-text-gray-300">
                            Health Worker
                          </label>
                        </div>
                      </li>
                      <li className="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark-border-gray-600">
                        <div className="flex items-center pl-2 bg-[#F3F3F3]">
                          <FormField
                            id="horizontal-list-radio-id"
                            type="radio"
                            value="corporate"
                            name="account_type"
                            onChange={handleChange}
                            checked={account_type === 'corporate'}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark-focus:ring-blue-600 dark-ring-offset-gray-700 dark-focus-ring-offset-gray-700 focus-ring-2 dark-bg-gray-600 dark-border-gray-500"
                          />
                          <label for="horizontal-list-radio-id" className="w-full py-3 ml-2 text-[13px] font-medium text-gray-900 dark-text-gray-300">
                            Corporate Organisation
                          </label>
                        </div>
                      </li>
                    </ul> */}
                    {errors.account_type && <div className="text-red-600">{errors.account_type}</div>}
                    <label className="font-medium">
                      <span className="flex px-2">Email</span>
                    </label>
                    <FormField
                      className="w-full text-black mt-2 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus-border-gray-600 shadow-sm rounded-lg"
                      name="email"
                      placeholder="example@gmail.com"
                      type="email"
                      required
                      value={email}
                      onChange={handleChange}
                    />
                    {errors.email && <div className="text-red-600">{errors.email}</div>}
                  </div>
                  <div>
                    <label className="font-medium">Password</label>
                    <div className="relative">
                      <FormField
                        className="w-full text-black mt-2 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus-border-gray-600 shadow-sm rounded-lg"
                        name="password"
                        placeholder="**********"
                        value={password}
                        type={passwordVisible ? 'text' : 'password'}
                        required
                        onChange={handleChange}
                      />
                      {errors.password && <div className="text-red-600">{errors.password}</div>}
                      <div className="absolute top-4 right-2">
                        <button type='button' onClick={togglePasswordVisibility} >
                          <img src={passwordVisible ? formIcons.eyedisabled : formIcons.eyedisabled} />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <label className="font-medium">Confirm Password</label>
                    <div className="relative">
                      <FormField
                        className="w-full text-black mt-2 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus-border-gray-600 shadow-sm rounded-lg"
                        name="cpassword"
                        placeholder="**********"
                        value={cpassword}
                        type={passwordVisible ? 'text' : 'password'}
                        required
                        onChange={handleChange}
                      />
                      {errors.cpassword && <div className="text-red-600">{errors.cpassword}</div>}
                      <div className="absolute top-4 right-2">
                        <button type='button' onClick={togglePasswordVisibility} >
                          <img src={passwordVisible ? formIcons.eyedisabled : formIcons.eyedisabled} />
                        </button>
                      </div>
                    </div>
                  </div>
                  
                
                  <div className="absolute top-[-0.5rem] right-[10rem] bg-gray w-10 h-10 px-4 py-2 rounded-2xl">
                      <button  onClick={() => setShowModal(false)}><h1 className='text-bold'>X</h1></button>
                    </div>
                 {loading ? 
                <button type="submit" className="w-full text-black px-4 py-2 text-white font-medium bg-[#47A7FD] hover-bg-[#47A7FD]-500
                active-bg-indigo-600 rounded duration-150
                    mr-2 mb-2" disabled={loading}>  
                      
                        <svg aria-hidden="true" className="inline w-8 h-8  text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                        </svg> 
                  </button>
                        : 
                        <button type="submit" className="w-full px-4 py-2 text-white font-medium bg-[#47A7FD] hover-bg-[#47A7FD]-500
                        active-bg-indigo-600 rounded duration-150" disabled={loading}> submit 
                        </button>
                    }
                </form>
                <div className="mt-5"></div>
              </div>
            </div>
          </main>
      </div>
      {showSuccessModal && (
  <div className="fixed inset-0 flex items-center justify-center z-50">
    <div className="modal-overlay fixed inset-0 bg-black opacity-50"></div>
    <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
      <div className="modal-content py-4 text-left px-6">
        <h2 className="text-2xl font-semibold mb-3">Success</h2>
        <p>{successMessage}</p>
        <button
          className="mt-4 bg-blue-500 text-white active:bg-blue-600 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          onClick={closeSuccessModal}
        >
          Close
        </button>
      </div>
    </div>
  </div>
)}
{showErrorModal && (
  <div className="fixed inset-0 flex items-center justify-center z-50">
    <div className="modal-overlay fixed inset-0 bg-black opacity-50"></div>
    <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto">
      <div className="modal-content py-4 text-left px-6">
        <h2 className="text-2xl font-semibold mb-3">Error</h2>
        <p>{errorMessage}</p>
        <button
          className="mt-4 bg-red-500 text-white active:bg-red-600 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          onClick={closeErrorModal}
        >
          Close
        </button>
      </div>
    </div>
  </div>
)}

    </div>
  );
};

export default Register;
