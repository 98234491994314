import mmbLogo from '../global/assets/logo.svg';
import guyImg from '../global/assets/guy.svg';
import leslisImg from '../global/assets/leslie.svg';
import janeImg from '../global/assets/jane.svg';
import penaImg from '../global/assets/pena.svg';
import wadeImg from '../global/assets/wade.svg';
import alexlieImg from '../global/assets/alexleslie.svg';
import cameronImg from '../global/assets/cameron.svg';
import saheedImg from '../global/assets/saheed.svg';
import jonesImg from '../global/assets/jones.svg';
import hawkingsGImg from '../global/assets/hawkinsg.svg';
import healthCareImg from '../global/assets/corporatehealthcare.svg';
import memberImg from '../global/assets/corporatemember.svg';
import sponsorImg from '../global/assets/corporateSponsor.svg';
import redLine from '../global/assets/redGraphL.svg';
import greenLine from '../global/assets/greenGraphL.svg';
import referrerImg from '../global/assets/referrerImg.svg';
import estherImage from '../global/assets/estherImage.svg';
import clientBookingImage from '../global/assets/ClientBookingImg.svg'
import flyer from '../global/assets/flyer.png';
import phrhelpcenterImg from '../global/assets/healthworkerImg.svg'
import phrhomecareImg from '../global/assets/homecareImg.svg'
import phrImg from '../global/assets/phrImg.svg'
import mmblogo2 from '../global/assets/512x512.png'
import Lifesavers from '../global/assets/Lifesavers.svg'
import qualification from '../global/assets/qualification.jpg'
import licence from '../global/assets/licence.png'
import registration from '../global/assets/registration.png'
import avatar from '../global/assets/avatar.jpg'
import Emergency from '../global/assets/emergency.jpg'
import informationIcon from '../global/assets/information.svg'
import ambulance from '../global/assets/ambulance-ehi.jpg'


export const logo = {mmbLogo, mmblogo2} 

export const emergency = {Emergency, ambulance}

export const withdrawalsImages = {guyImg,leslisImg,janeImg,penaImg,wadeImg};

export  const msgImages = {alexlieImg,cameronImg,saheedImg,jonesImg,hawkingsGImg};

export const incomeImg ={redLine, greenLine, informationIcon}

export const corporatePageImages = {healthCareImg, memberImg,sponsorImg}

export const userSidebarImage = {referrerImg}

export const healthWorkerImages = {estherImage}

export const clientsImages = {clientBookingImage }

export const LoginImage = {flyer}

export const phrImages ={phrhelpcenterImg,phrImg, phrhomecareImg}

export const doctor = {Lifesavers}

export const  uploads = {qualification, licence, registration, avatar }

