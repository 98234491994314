import React, {useState, useEffect} from "react";
import { minibar } from "../../constants/icons";
import { UserProfileCards } from "../../services/data/userprofileData";
import axios from "axios";
import axiosInstance from '../../services/axiosconfig/AxiosAuth'
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";




const ProfileDetails = () => {
  const[userData, setUserData] = useState("");
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  

  const userId = useParams();  // Get the userId from the URL


  useEffect(() => {
    const apiUrl = `https://stagging.mymedicalbank.com/api/v1/admin/get-user/${userId.id}`;
    // console.log(apiUrl);   
    axiosInstance.get(apiUrl)
     .then((response) => {
      setUserData(response.data);
      setLoading(false);
      console.log("success", response.data)
     })
     .catch((error) => {
      setLoading(false);
      console.log("fetching error", error)

     });

  }, [])

  const handleDelete = () => {
    const apiUrl = `https://stagging.mymedicalbank.com/api/v1/admin/delete-user/${userId.id}`;
    
    axiosInstance.delete(apiUrl)
    .then(() => {
      setShowModal(false);
      navigate('/all-users');
      console.log('deleted')
    })
    .catch((error) => {
      console.error("Error deleting user:", error);
    });
  };

  
  return (
    <>
      <>
        <section className="fixed lg:w-[333px] md:w-[200px] py-[11px] h-full   navigation-shadow  ">
         <div className="w-[333px] h-full max-w-md p-4 bg-white border rounded-3xl border-gray-200 shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700">
            <div className="flow-root">
                    <ul role="list" className="">
                        <li className="py-3 sm:py-4">
                            <div className="flex items-center space-x-4">
                                <div className="flex-shrink-0">
                                    <img className="w-14 h-14 rounded-full" src={`/uploads/${userData?.data?.imageUrl}`} alt="profile image"/>
                                </div>
                                <div className="flex-1 min-w-0">
                                    <p className="text-[#25C0FF] font-bold text-[18px] font-Roboto leading-[21px]">
                                      {userData?.data?.first_name} {userData?.data?.last_name}
                                    </p>
                                    <p className="text-[12px] leading-[14.06px] font-normal font-Roboto text-black py-2">
                                    USER ID: {userData?.data?.patient_id}
                                    </p>
                                </div>
                            </div>
                        </li>
                      
                        <li className="py-3 sm:py-4">
                            <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                    <p className="text-[18px] font-bold font-Roboto text-[#0F3F62] leading-[21.09px]">
                                    User Information
                                    </p>
                                </div>
                                <div className="inline-flex items-center text-[22px] font-bold text-[#25C0FF] ">
                                  <a href="">...</a>
                                </div>
                            </div>
                        </li>
                        </ul>

                        <ul role="list" className="">
                        <li className="py-2">
                            <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                    <p className="text-[14px] font-normal font-Roboto text-black leading-[14.06px]">
                                    Date of Birth:
                                    </p>
                                </div>
                                <div className="inline-flex items-center text-[12px] font-bold leading-[14.06px] text-[#0F3F62] ">
                                {userData?.data?.dateOfBirth}
                                </div>
                            </div>
                        </li>
                        <li className="py-2">
                            <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                    <p className="text-[14px] font-normal font-Roboto text-black leading-[14.06px]">
                                    Gender:
                                    </p>
                                </div>
                                <div className="inline-flex items-center text-[12px] font-bold leading-[14.06px] text-[#0F3F62] ">
                                {userData?.data?.gender}
                                </div>
                            </div>
                        </li>
                        <li className="py-2">
                            <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                    <p className="text-[14px] font-normal font-Roboto text-black leading-[14.06px]">
                                    Mobile Number:
                                    </p>
                                </div>
                                <div className="inline-flex items-center text-[12px] font-bold leading-[14.06px] text-[#0F3F62] ">
                                {userData?.data?.mobile}
                                </div>
                            </div>
                        </li>
                        <li className="py-2">
                            <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                    <p className="text-[14px] font-normal font-Roboto text-black leading-[14.06px]">
                                    Email Address:
                                    </p>
                                </div>
                                <div className="inline-flex items-center text-[12px] font-bold leading-[14.06px] text-[#0F3F62] ">
                                {userData?.data?.email}
                                </div>
                            </div>
                        </li>
                        <li className="py-2">
                            <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                    <p className="text-[14px] font-normal font-Roboto text-black leading-[14.06px]">
                                    User Referral Code: 
                                    </p>
                                </div>
                                <div className="inline-flex items-center text-[12px] font-bold leading-[14.06px] text-[#0F3F62] ">
                                {userData?.data?.invite_code}
                                </div>
                            </div>
                        </li>
                        <li className="py-2">
                            <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                    <p className="text-[14px] font-normal font-Roboto text-black leading-[14.06px]">
                                    Referral Balance:
                                    </p>
                                </div>
                                <div className="inline-flex items-center text-[12px] font-bold leading-[14.06px] text-[#0F3F62] ">
                            
                                </div>
                            </div>
                        </li>
                        <li className="py-2">
                            <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                    <p className="text-[14px] font-normal font-Roboto text-black leading-[14.06px]">
                                    User Address:
                                    </p>
                                </div>
                                <div className="inline-flex items-center text-[12px] font-bold leading-[14.06px] text-[#0F3F62] ">
                                {userData?.data?.address}
                                </div>
                            </div>
                        </li>
                        <li className="py-2">
                            <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                    <p className="text-[14px] font-normal font-Roboto text-black leading-[14.06px]">
                                    City:
                                    </p>
                                </div>
                                <div className="inline-flex items-center text-[12px] font-bold leading-[14.06px] text-[#0F3F62] ">
                                {userData?.data?.city}
                                </div>
                            </div>
                        </li>
                        <li className="py-2">
                            <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                    <p className="text-[14px] font-normal font-Roboto text-black leading-[14.06px]">
                                    Country:
                                    </p>
                                </div>
                                <div className="inline-flex items-center text-[12px] font-bold leading-[14.06px] text-[#0F3F62] ">
                                {userData?.data?.nationality}
                                </div>
                            </div>
                        </li>
                        <li className="py-2">
                            <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                    <p className="text-[14px] font-normal font-Roboto text-black leading-[14.06px]">
                                    Subscribed:
                                    </p>
                                </div>
                                {userData?.data?.subscription_status === 1 ? (
                                <div className="inline-flex items-center text-[12px] font-bold leading-[14.06px] text-[#0F3F62] ">
                                   YES
                                </div> ) : (
                                 <div className="inline-flex items-center text-[12px] font-bold leading-[14.06px] text-[#0F3F62] ">
                                 No
                              </div>
                                )}
                              
                            </div>
                        </li>
                     
                        </ul>
            </div>
              <div className="text-center py-9 bg-red">
              <button  onClick={() => setShowModal(true)} class="h-10 px-5 m-2 text-red-100 transition-colors duration-150 bg-red-700
               rounded-lg focus:shadow-outline hover:bg-red-800">
                Delete User
                
                </button>
                      

      {showModal && (
         <div className="fixed inset-0 z-10 overflow-y-auto">
         <div
             className="fixed inset-0 w-full h-full bg-black opacity-40"
             onClick={() => setShowModal(false)}>
        </div>
         <div className="flex items-center min-h-screen px-4 py-8">
             <div className="relative w-full max-w-sm p-4 mx-auto bg-white rounded-md shadow-lg">
                 <div className="mt-3 ">
                     <div className="flex items-center justify-center flex-none w-12 h-12 mx-auto bg-red-100 rounded-full">
                         <svg
                             xmlns="http://www.w3.org/2000/svg"
                             className="w-6 h-6 text-red-600"
                             viewBox="0 0 20 20"
                             fill="currentColor"
                         >
                             <path
                                 fillRule="evenodd"
                                 d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                 clipRule="evenodd"
                             />
                         </svg>
                     </div>
                     <div className="mt-2 text-center sm:ml-4 ">
                         <h4 className="text-lg font-medium text-gray-800">
                           Are you Sure?
                         </h4>
                         <p className="mt-2 text-[15px] leading-relaxed text-gray-500">
                          you want to delete this user?
                         </p>
                         <div className="items-center gap-2 mt-3 sm:flex">
                             <button
                                 className="w-full mt-2 p-2.5 flex-1 text-white bg-red-600 rounded-md outline-none ring-offset-2 ring-red-600 focus:ring-2"
                                 onClick={() => handleDelete()}
                             >
                               Delete
                             </button>
                             <button
                                 className="w-full mt-2 p-2.5 flex-1 text-gray-800 rounded-md outline-none border ring-offset-2 ring-indigo-600 focus:ring-2"
                                 onClick={() => setShowModal(false)}
                             >
                                 Cancel
                             </button>
                         </div>
                     </div>
                 </div>
             </div>
         </div>
     </div>
        // <div className="fixed inset-0 flex items-center justify-center z-50 ">
        //   <div className="modal-container ">
        //     <div className="modal-content bg-white px-6 py-6">
        //       <p className="mb-4">Are you sure you want to delete this user?</p>
        //       <div className="flex justify-end">
        //         <button
        //           className="bg-red-500 text-white px-4 py-2 rounded-md mr-2 hover:bg-red-600"
        //           onClick={handleDelete}
        //         >
        //           Delete
        //         </button>
        //         <button
        //           className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400"
        //           onClick={() => setShowModal(false)}
        //         >
        //           Cancel
        //         </button>
        //       </div>
        //     </div>
        //   </div>
        // </div>
      )}

      
              </div>
         </div>

        
        </section>
     
      </>
    </>
  );
};

export default ProfileDetails;
