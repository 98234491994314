import React, { useState, useEffect, useRef } from 'react';
import { customStyles } from './customStyles';
import { healthWorkerIcons } from '../../../constants/icons';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import Select from 'react-select';
import FormField from '../../FormField';
import { specialty } from '../../../services/data/services';
import { appointmentDataIcons } from '../../../constants/icons';
import { useDispatch, useSelector } from 'react-redux';
import { submitForm } from '../../../services/redux/actions/formAction';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllOrg } from '../../../services/redux/actions/formAction'



const ProfileForm = () => {
  const [step, setStep] = useState(1);
 
 
  // Create a function to handle the "Next" button click
  const handleNextClick = () => {
    setStep(step + 1);
  };

  // Create a function to handle the "Previous" button click
  const handlePrevClick = () => {
    setStep(step - 1);
  };

    // Define your default values for id and email
    const { id: defaultId } = useParams();
    console.log(defaultId)
   
    //get all organisation
    const dispatch = useDispatch()

    const { orgDetails,  error } = useSelector((state) => state.orgDetails);
    console.log('org', orgDetails);

   

    const defaultEmail = "peter.awe@ebonytechnology.co.uk";
    const defaultorganisationId = "1";
    let items;

    if (!defaultId) {
      items = ['mymedicalbank', 'other organisation'];
    } else {
      items = ['mymedicalbank'];
    }
 

  

  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    city: '',
    address: '',
    country: '',
    state: '',
    service_type: [],
    start_date: '',
    email: defaultEmail,
    id: defaultId,
    edu_cert: null,
    passport_photo:null,
    license:null,
    reg_cert:null,
    // license_renewal: '',
    specialty: '',
    gender: '',
  })

  const [phone, setPhone] = useState('');

  const fileInputRefs = useRef({
    edu_cert: React.createRef(),
    passport_photo: React.createRef(),
    license: React.createRef(),
    reg_cert: React.createRef(),
  });


// Handle file uploads
const handleFileChange = (e, key) => {
  const selectedFile = e.currentTarget.files[0];
  console.log('Selected File:', selectedFile);

  if (selectedFile) {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: selectedFile,
    }));
  }
};

const [mobile, setMobile] = useState('');



const [selectedItem, setSelectedItem] = useState('');

const handleSelectChange = (e) => {
  if (e && e.target) {
    setSelectedItem(e.target.value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      // Remove 'type' from here
    }));
  }
};

const handleMultiSelectChange = (selectedOptions) => {
  // Extract values from selectedOptions
  const selectedValues = selectedOptions.map((option) => option.value);

  console.log("Selected Values:", selectedValues);

  setFormData((prevFormData) => ({
    ...prevFormData,
    service_type: selectedValues,
  }));
};


            // Handle the change event
            const handleChange = (e) => {
              if (e.target) {
                const { name, value } = e.target;
                console.log(`Regular input changed: ${name} => ${value}`);
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  [name]: value,
                }));
              } else if (e && e.name === 'mobile') {
                // Check if e is a phone number (assuming 'mobile' is the name of the phone input field)
                console.log(`Phone input changed: ${e.name} => ${e.value}`);
                setMobile(e.value);
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  [e.name]: e.value,
                }));
              }
            };
  
  
  
   



  const handleClick = (key) => {
    const fileInputRef = fileInputRefs.current[key];
    console.log('File input clicked:', key);
  
    if (fileInputRef && fileInputRef.current) {
      // Make the file input visible and positioned off-screen
      fileInputRef.current.style.position = 'absolute';
      fileInputRef.current.style.left = '-9999px';
  
      // Trigger a click on the file input
      fileInputRef.current.click();
  
      // After the user selects a file, reset the styles to hide the file input
      fileInputRef.current.addEventListener('change', () => {
        fileInputRef.current.style.position = 'none';
        fileInputRef.current.style.left = 'none';
      });
    }
  };
  
  

  const loading = useSelector((state) => state.profileForm.loading);
  // const error = useSelector((state) => state.profileForm.error);
  // const success = useSelector((state) => state.ProfileForm.success);

  
  const handleSubmit = async (e) => {
    e.preventDefault();

    const Health = {
    first_name: formData.first_name,
    last_name: formData.last_name,
    service_type: formData.service_type,
    city: formData.city,
    address: formData.address,
    country: formData.country,
    state: formData.state,
    start_date: formData.start_date,
    edu_cert: formData.edu_cert,
    passport_photo: formData.passport_photo,
    license:formData.license,
    reg_cert:formData.reg_cert,
    // license_renewal: formData.license_renewal,
    // date: formData.date,
    specialty: formData.specialty,
    gender: formData.gender,
    };
  
    // Create a new FormData object
    const formDataToSend = new FormData();
  
    // Append regular form fields
    Object.entries(Health).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        // If the value is an array, append each element with its index
        value.forEach((element, index) => {
          formDataToSend.append(`${key}[${index}]`, element);
        });
      } else {
        formDataToSend.append(key, value === 'null' ? null : value);
      }
    });
  
    // Append files
    Object.entries(formData).forEach(([key, file]) => {
      if (key.startsWith('file') && file) {
        formDataToSend.append(key, file, file.name);
      }
    });
  
    console.log(formData);
    console.log(formDataToSend);
  
    // Dispatch the action to submit the form with formData
    dispatch(submitForm(formDataToSend));
  };
  
  
  
  
  

  // Define form fields for each step within switch cases
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div>
            <div className="flex flex-col items-center gap-y-5 gap-x-6 [&>*]:w-full sm:flex-row">
              <div>
                <label className="font-medium">First name</label>
                <FormField
                  type="text"
                  name="first_name"
                  placeholder="Joe"
                  value={formData.first_name}
                  onChange={(e) => handleChange(e)} 
                  required
                  className="w-full mb-4 mt-2 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
                />
              </div> 
              <div>
                <label className="font-medium">Last name</label>
                <FormField
                  placeholder="Doe"
                  name='last_name'
                  value={formData.last_name}
                  type="text"
                  onChange={(e) => handleChange(e)} 
                  required
                  className="w-full mb-4 mt-2 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
                />
              </div>
            </div>
        <div>
              <label className="font-medium  max-w-xl ">Phone number</label>
              <PhoneInput
                defaultCountry="ng"
                type="phone"
                name="mobile"
                value={mobile}
                onChange={(value) => handleChange({ name: 'mobile', value })}
                containerStyle={{
                  border: '0.5rem ',
                  width: '100%',
                }}
                inputStyle={{
                  background: '#F3F3F3',
                  width: '100%',
                  padding: '0.5rem 1rem',
                  borderRadius: '0.5rem',
                  marginBottom: '1rem',
                  border: '0.5rem',
                }}
              />
            </div> 
            <div className="relative">
              <label className="font-medium max-w-xl">Gender</label>
              <select
                  name='gender'
                  value={formData.gender}
                  onChange={(e) => handleChange(e)} 
                  className="w-full mb-4 mt-2 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border
                   focus:border-gray-600 shadow-sm rounded-lg"
                >
                  <option value="" disabled>Select Gender</option>
                  <option value="oprtion1">Male</option>
                  <option value="oprtion2">Female</option>
                </select>
              <div className="absolute top-[3.5rem] right-4 transform -translate-y-1/2">
              <img src={appointmentDataIcons.dropdownIcon} className='w-3 h-3'/>
              </div>
            </div>
            <div>
              <label className="font-medium max-w-xl">Address</label>
              <FormField
                type="address"
                name="address"
                value={formData.address}
                placeholder="Ikeja lagos State"
                required
                onChange={(e) => handleChange(e)} 
                className="w-full mb-4 mt-2 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
              />
            </div>
            <div>
              <label className="font-medium max-w-xl">Country</label>
              <FormField
               onChange={(e) => handleChange(e)} 
               value={formData.country}
                type="text"
                name='country'
                placeholder="Nigeria"
                required
                className="w-full mb-4 mt-2 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
              />
            </div>
            <div>
              <label className="font-medium max-w-xl">State</label>
              <FormField
                onChange={(e) => handleChange(e)} 
                value={formData.state}
                type="text"
                name='state'
                placeholder="Lagos State"
                required
                className="w-full mb-4 mt-2 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
              />
            </div>
            <div>
              <label className="font-medium max-w-xl">City</label>
              <FormField
                onChange={(e) => handleChange(e)} 
                value={formData.city}
                type="text"
                name='city'
                placeholder="Ikeja"
                required
                className="w-full mb-4 mt-2 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
              />
            </div>
            <div>
            <div className="relative">
              <label className="font-medium max-w-xl">Organisation</label>
              <select
                name='organisationId'
                className="w-full mb-4 mt-2 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
                value={selectedItem}
                onChange={handleSelectChange}
              >
                <option value="" disabled>Mymedicalbank</option>
                {items?.map((item, index) => (
                  <option key={index} value={index + 1}>
                    {item}
                  </option>
                ))}
              </select>
              <div className="absolute top-[3.5rem] right-4 transform -translate-y-1/2">
              <img src={appointmentDataIcons.dropdownIcon} className='w-3 h-3'/>
              </div>
            </div>
            </div>
           <div>
          <label className="font-medium max-w-xl">Type of Service</label>
          <Select
            id="multiselect"
            name='service_type'
            isMulti
            // Ensure formData.service_type is an array
            onChange={handleMultiSelectChange}
            className="w-full mb-4"
            styles={customStyles}
            options={[
              { value: "value1", label: "Value 1" },
              { value: "value2", label: "Value 2" },
              { value: "value3", label: "Value 3" },
            ]}
          />

        </div> 
          </div>
        
        );
      case 2:
        return (
          <div>
            <div className="flex flex-col items-center gap-y-5 gap-x-6 [&>*]:w-full sm:flex-row">
            </div>
            <div className="bg-[#F3F3F3] w-full h-[73px] border-[1px] rounded-xl">
            <label htmlFor="fileInput-edu_cert" className="cursor-pointer">
              <img src={healthWorkerIcons.dashicons} className='px-[10rem] py-2 items-center'   onClick={() => handleClick('edu_cert')} />
              <p className="text-center text-[#47A7FD] text-sm">
                <span className="text-black">
                  {
                  formData.edu_cert && formData.edu_cert.name ? `Selected File: ${formData.edu_cert.name}` : ' Click here to Add your files here '}
                </span>
              </p>
            </label>
            <input
                id="fileInput-edu_cert"
                name='edu_cert'
                type="file"
                accept=".pdf, .jpg, .png"
                style={{ display: 'none' }}
                onChange={(e) => handleFileChange(e, 'edu_cert')}
                ref={fileInputRefs.edu_cert}  
              />

          </div>
            <div>
              <label className="font-medium max-w-xl text-[#47A7FD]">
                <span className="text-[#D60000]">*</span><i>Upload Passport Photograph</i> 
              </label>
              <div className="bg-[#F3F3F3] w-full h-[73px] border-[1px] rounded-xl">
              <label htmlFor="fileInput-passport_photo" className="cursor-pointer">
                <img src={healthWorkerIcons.dashicons} className='px-[10rem] py-2 items-center' />
                <p className="text-center text-[#47A7FD] text-sm ">
                <span className="text-black">
                {formData.passport_photo && formData.passport_photo.name ? `Selected File: ${formData.passport_photo.name}` : ' Click here to Add your files here '}
                </span>
                </p>
                 </label>
                <input
                   id="fileInput-passport_photo"
                  name='passport_photo'
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(e) => handleFileChange(e, 'passport_photo')}
                  ref={fileInputRefs.passport_photo} 
                />
              </div>
              <h1 className="max-w-[15rem] text-[12px] py-2">
                PDF, JPEG & PNG are accepted. File should be within 10mb.
              </h1>
            
            </div>
          
          <div class="flex flex-col items-center justify-center  py-8 mx-auto  lg:py-0">
            <div class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                <div className="relative">
              <label className="font-medium max-w-xl">Specialty</label>
              <select
                id="dropdown"
                name='specialty'
                className="w-full mb-4 mt-2 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
                onChange={(e) => handleChange(e)} 
              >
                <option value="">Select Specialty</option>
                {specialty?.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
              <div className="absolute top-[3.5rem] right-4 transform -translate-y-1/2">
              <img src={appointmentDataIcons.dropdownIcon} className='w-3 h-3'/>
              </div>
            </div>
                <div className="flex flex-col items-center gap-y-5 gap-x-6 [&>*]:w-full sm:flex-row">
              <div>
                <label className="font-medium">Start Date</label>
                <FormField
                  onChange={(e) => handleChange(e)} 
                  value={formData.start_date}
                  type="date"
                  name='start_date'
                  placeholder="Joe"
                  required
                  className="w-full mb-4 mt-2 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
                />
              </div>
            </div>
            <div>
              <label className="font-medium max-w-xl text-[#47A7FD]">
                <span className="text-[#D60000]">*</span><i> Upload license</i> 
              </label>
              <div className="bg-[#F3F3F3] w-full h-[73px] border-[1px] rounded-xl">
              <label htmlFor="fileInput-license" className="cursor-pointer">
                <img src={healthWorkerIcons.dashicons} className='px-[10rem] py-2 items-center' onClick={() => handleClick('license')}  />
                <p className="text-center text-[#47A7FD] text-sm cursor-auto" onClick={() => handleClick('license')}>
                <span className="text-black">
                {formData.license && formData.license.name ? `Selected File: ${formData.license.name}` : ' Click here to Add your files here '}
                </span>
                </p>
                </label>
                <input
                  id="fileInput-license"
                  name='license'
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(e) => handleFileChange(e, 'license')}
                  ref={fileInputRefs.license} 
                />
              </div>
              <h1 className="max-w-[15rem] text-[12px] py-2">
                PDF, JPEG & PNG are accepted. File should be within 10mb.
              </h1>
            </div>
            <div>
              <label className="font-medium max-w-xl text-[#47A7FD]">
                <span className="text-[#D60000]">*</span><i> Upload Certificate of Registration</i> 
              </label>
              <div className="bg-[#F3F3F3] w-full h-[73px] border-[1px] rounded-xl">
              <label htmlFor="fileInput-reg_cert" className="cursor-pointer">
                <img src={healthWorkerIcons.dashicons} className='pl-[8rem] py-2 items-center' onClick={() => handleClick('reg_cert')}  />
                <p className="text-center text-[#47A7FD] text-sm" onClick={() => handleClick('reg_cert')} >
                 <span className='text-black'> 
                   {formData.reg_cert && formData.reg_cert.name ? `Selected File: ${formData.reg_cert.name}` : ' Click here to Add your files here '}</span>
                </p>
                </label>
                <input
                   id="fileInput-reg_cert"
                  name='reg_cert'
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(e) => handleFileChange(e, 'reg_cert')}
                  ref={fileInputRefs.reg_cert} 
                />
              </div>
              <h1 className="max-w-[15rem] text-[12px] py-2">
                PDF, JPEG & PNG are accepted. File should be within 10mb.
              </h1>
            </div>
            {/* <div className="flex flex-col items-center gap-y-5 gap-x-6 [&>*]:w-full sm:flex-row">
              <div>
                <label className="font-medium">Licence Renewal Date</label>
                <FormField
                  onChange={(e) => handleChange(e)} 
                  value={formData.date}
                  type="date"
                  name='license_renewal'
                  required
                  className="w-full mb-4 mt-2 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
                />
              </div>
            </div> */}
            <button  type="submit" onClick={handleSubmit}  className="text-white w-[20rem] bg-bgbutton hover:bg-bgbutton focus:ring-4 
                focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2.5 text-center
                 dark:bg-bgbutton-600 dark:hover:bg-bgbutton-700 dark:focus:ring-bgbutton-800">Submit
                 </button>
              
          </div>
      </div>
  </div>

       
   
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="bg-white w-[926px] h-[862px] rounded-[20px]">
      <main className="py-8">
        <div className="flex">
          <div className="w-1/2">
            <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
              <div className="max-w-lg space-y-3 sm:text-center">
                <p className="text-[#47A7FD] text-sm text-start font-semibold sm:text-[16px] leading-[20px] font-Poppins">
                  Qualification
                </p>
              </div>
              <div className="mt-4 max-w-lg">
                <form
                  onSubmit={(e) => e.preventDefault()}
                  className="space-y-5"
                >
                  {renderStep()}
                  <div className="flex justify-between">
                    {step > 1 && (
                      <button
                        type="button"
                        className="mx-[40rem] text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 mt-8 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
                        onClick={handlePrevClick}
                      >
                        Previous
                      </button>
                    )}
                    {step < 2 ? (
                      <button
                        type="button"
                        className="mx-[40rem] text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800
                         focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5
                          text-center mr-2 mb-2 mt-8 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white
                           dark:hover:bg-blue-500 dark:focus:ring-blue-800"
                        onClick={handleNextClick}
                      >
                        Next
                      </button>
                    ) : (
                      null
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};


export default ProfileForm;
