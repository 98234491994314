import React, { useState, useRef, useEffect } from 'react';
import { healthWorkerIcons } from '../../../constants/icons';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css'
import Select from 'react-select';
import FormField from "../../FormField"
import { useDispatch, useSelector } from 'react-redux';
import { submitOrgRegForm } from '../../../services/redux/actions/formAction';
import { useNavigate, useParams } from 'react-router-dom';
import Error from '../../global/Error';
import {Tooltips} from '../../../global/tooltip/Tooltip'
import {incomeImg} from '../../../constants/images'









      const customStyles = {
        control: (provided, state) => ({
          ...provided,
          width: '100%',
          border: '1px solid gray',
          borderRadius: '0.5rem',
          backgroundColor: '#F3F3F3'
          
        }),
        multiValue: (provided) => ({
          ...provided,
          background: '#3182CE',
          color: 'white',
          borderRadius: '0.25rem',
          padding: '0.2rem 0.5rem',
        }),
        multiValueLabel: (provided) => ({
          ...provided,
          color: 'white',
        }),
        multiValueRemove: (provided) => ({
          ...provided,
          color: 'white',
          ':hover': {
            background: 'transparent',
            color: 'white',
          },
        }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? '#3182CE' : 'white',
          color: state.isSelected ? 'white' : 'black',
        }),
      };

   

      const ProfileForm = () => {
      const [formData, setFormData] = useState({
        location_city: '',
        name: '',
        address: '',
        country: '',
        location_state: '',
        serviceTypes: [],
        email: '',
        logo:null,
        cac:null,
        type:'',
        website: '',
        bank_name: '',
        account_number: '',
        account_name: '',

      })

    
    const [step, setStep] = useState(1);
    const fileInputRef = useRef(null);

      // Create a function to handle the "Next" button click
      const handleNextClick = () => {
        setStep(step + 1);
        
    };

 

    
    // Create a function to handle the "Previous" button click
    const handlePrevClick = () => {
        setStep(step - 1);
    };

  

    const fileInputRefs = useRef({
      logo: React.createRef(),
      cac: React.createRef(),
     
    });

       
// Handle file uploads
const handleFileChange = (e, key) => {
  const selectedFile = e.currentTarget.files[0];
  console.log('Selected File:', selectedFile);

  if (selectedFile) {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [key]: selectedFile,
    }));
  }
};
    
    // define state to display all county codes and flags on phone input
    const [mobile, setMobile] = useState('');


// organisation input  dropdown 
    const [selectedItem, setSelectedItem] = useState('');
    const items = ['Ambulance Service', 'Blood Supply', 'Care Home for the Elderly','Dental Health Care', 'Diagnostic Test', 'Dietary & Nutrition',
  'Electronic Medical Record', 'Eye Health Care', 'General Health Care', 'Health Charity', 'Health Data, Research & Analytics', 'Insurance',
 'Healthcare Logistics & Supplies', 'Healthcare Management & Consultancy', 'Healthcare Products', 'Healthcare Recruitment', 'Healthcare Training',
 'Home Medical & Personal Care', 'Medical Legal Services', 'Mental Health Care', 'Occupational Health', 'Pharmacy', 'Physiotherapy', 'Sexual, Reproductive & Maternal Health',
'Substance Abuse Treatment', 'Telemedicine' ];
  
const handleSelectChange = (e) => {
  if (e && e.target) {
    setSelectedItem(e.target.value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      type: e.target.value, // Update formData with the selected value
    }));
  }
};


            // Handle the change event
            const handleChange = (e) => {
              if (e.target) {
                const { name, value } = e.target;
                console.log(`Regular input changed: ${name} => ${value}`);
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  [name]: value,
                }));
              } else if (e && e.name === 'mobile') {
                // Check if e is a phone number (assuming 'mobile' is the name of the phone input field)
                console.log(`Phone input changed: ${e.name} => ${e.value}`);
                setMobile(e.value);
                setFormData((prevFormData) => ({
                  ...prevFormData,
                  [e.name]: e.value,
                }));
              }
            };

    
            const handleMultiSelectChange = (selectedOptions) => {
              // Extract values from selectedOptions
              const selectedValues = selectedOptions.map((option) => option.value);
            
              console.log("Selected Values:", selectedValues);
            
              setFormData((prevFormData) => ({
                ...prevFormData,
                serviceTypes: selectedValues,
              }));
            };
      

      const { forminfo, error, loading } = useSelector((state) => state.profileForm);
    
      const dispatch = useDispatch()

      const navigate = useNavigate();

      // Redirect user to user info screen
  useEffect(() => {
    
    if (forminfo) {
      navigate(`/corporate-admin-dashboard`);
      console.log('forminfo',  forminfo)
    }
  }, [navigate, forminfo]); 
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    const organisation = {
      serviceTypes: formData.serviceTypes,
      name: formData.name,
      mobile: mobile,
      address: formData.address,
      country: formData.country,
      location_state: formData.location_state,
      location_city: formData.location_city,
      type: selectedItem,
      email: formData.email,
      website: formData.website,
      account_name: formData.account_name,
      account_number: formData.account_number,
      bank_name: formData.bank_name,
      logo: formData.logo ? formData.logo : null,
      cac: formData.cac ? formData.cac : null,
    };

    const payload = {
      organisation: {
        ...organisation
      },
      // Add other fields as needed
    };
  
   // Create a new FormData object
   const formDataToSend = new FormData();

     // Append regular form fields
  Object.entries(organisation).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      // If the value is an array, append each element with its index
      value.forEach((element, index) => {
        formDataToSend.append(`${key}[${index}]`, element);
      });
    } else {
      formDataToSend.append(key, value === 'null' ? null : value);
    }
  });
 
   // Append files
   Object.entries(formData).forEach(([key, file]) => {
     if (key.startsWith('file') && file) {
       formDataToSend.append(key, file, file.name);
     }
   });
 
   console.log('organizationData', organisation);
   console.log('formDataToSend', formDataToSend);
 
   // Dispatch the action to submit the form with formData
   dispatch(submitOrgRegForm(formDataToSend));
 };




        // You can define your form fields for each step within switch cases
    const renderStep = () => {
        switch (step) {
            case 1:
                return (
                    <div>
                        <div>
                        <label className="font-medium max-w-xl flex">
                            Name of Organisation
                            <div>
                        </div>
                        </label>
                                <FormField
                                type="text"
                                name="name"
                                onChange={handleChange}
                                placeholder='Enter Organisation Name'
                                className="w-full mb-4 mt-2 bg-[#F3F3F3] px-4  py-2 text-gray-500  outline-none border
                                 focus:border-gray-600 shadow-sm rounded-lg"
                        />
                    </div>
                    <div>
                        <label className="font-medium max-w-xl">
                            Phone number
                        </label>
                            <PhoneInput 
                            defaultCountry="ng"
                            type="phone"
                            name="mobile"
                            value={mobile}
                            onChange={(value) => handleChange({ name: 'mobile', value })}
                            containerStyle={{
                                border: "50rem ",
                                width: '100%',
                                background: "#F3F3F3",
                              
                              }}
                            inputStyle={{
                                background: "#F3F3F3",
                                width: '100%',
                                border: "50rem",
                              }}
                              className="w-full mb-4 mt-2 bg-[#F3F3F3] px-4  py-1 text-gray-500 
                              outline-none border focus:border-gray-600 shadow-sm rounded-lg"
                        />
                    </div>
                    <div>
                        <label className="font-medium max-w-xl">
                            Address
                        </label>
                        <FormField
                                type="text"
                                name="address"
                                onChange={handleChange}
                                placeholder='Enter your Business Address'
                                required
                                className="w-full mb-4 mt-2 bg-[#F3F3F3] px-4  py-2 text-gray-500 
                                 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
                        />
                    </div>
                    <div>
                        <label className="font-medium max-w-xl">
                           Country
                        </label>
                        <FormField
                                type="text"
                                name="country"
                                placeholder='E.g Nigeria'
                                onChange={handleChange}
                                className="w-full mb-4 mt-2 bg-[#F3F3F3] px-4  py-2 text-gray-500  outline-none border focus:border-gray-600 shadow-sm rounded-lg"
                            />
                    </div>
                    <div>
                        <label className="font-medium max-w-xl">
                           State
                        </label>
                        <FormField
                                type="text"
                                name="location_state"
                                placeholder='E.g Lagos State'
                                onChange={handleChange}
                                className="w-full mb-4 mt-2 bg-[#F3F3F3] px-4  py-2 text-gray-500  outline-none border focus:border-gray-600 shadow-sm rounded-lg"
                            />
                    </div>
                    <div>
                        <label className="font-medium max-w-xl">
                           City
                        </label>
                        <FormField
                                type="text"
                                name="location_city"
                                placeholder='E.g Ikeja '
                                onChange={handleChange}
                                className="w-full mb-4 mt-2 bg-[#F3F3F3] px-4  py-2 text-gray-500  outline-none border focus:border-gray-600 shadow-sm rounded-lg"
                            />
                    </div>
                    <div>
                        <label className="font-medium max-w-xl">
                          Organisation Type
                        </label>
                        <select
                          name="type"
                          id="dropdown"
                          className="w-full mb-4 mt-2 bg-[#F3F3F3] px-4 py-2 text-gray-500 outline-none border focus:border-gray-600 shadow-sm rounded-lg"
                          value={selectedItem}
                          onChange={handleSelectChange}
                        >
                          <option value="">Select an Organisation</option>
                          {items.map((item) => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>
                               
                           
                    </div>
                    <label className="font-medium max-w-xl flex py-2">
                      Type of Service
                      <Tooltips message="Select 1 or more services your organisation will be providing to the public through the MyMedicalBank platform.">
                          <div className="relative inline-block">
                            <img src={incomeImg.informationIcon} className="ml-2  h-6 w-6 text-blue-500" alt="Tooltip" />
                          </div>
                        </Tooltips>
                      </label>
                    <Select
                      id="multiselect"
                      name='serviceTypes'
                      isMulti
                      onChange={handleMultiSelectChange}
                      className="w-full mb-4"
                      styles={customStyles}
                      options={[
                        { value: "Emergency Health Information", label: "Emergency Health Information " },
                        { value: "Homecare", label: "Homecare" },
                        { value: "Health Market", label: "Health Market" },
                        { value: "Telemedicine", label: "Telemedicine" },
                        { value: "Clinic ", label: "Clinic Visit" },
                        { value: "Health Jobs", label: "Health Jobs" },
                      ]}
                    />
                    <div>
                        <label className="font-medium max-w-xl">
                          Business Email
                        </label>
                        <FormField
                                type="text"
                                name="email"
                                placeholder='Your Business Email Address '
                                onChange={handleChange}
                                className="w-full mb-4 mt-2 bg-[#F3F3F3] px-4  py-2 text-gray-500  outline-none border focus:border-gray-600 shadow-sm rounded-lg"
                            />
                    </div>
                    <div>
                        <label className="font-medium max-w-xl">
                          Business Website
                        </label>
                        <FormField
                                type="text"
                                name="website"
                                placeholder='Your Business Website '
                                 onChange={handleChange}
                                className="w-full mb-4 mt-2 bg-[#F3F3F3] px-4  py-2 text-gray-500  outline-none border focus:border-gray-600 shadow-sm rounded-lg"
                            />
                    </div>
          
                    </div>
                );
            case 2:
                return (
                    <div>
                           <div className="flex flex-col items-center  gap-y-5 gap-x-6 [&>*]:w-full sm:flex-row">
                      
                    </div>
                
                
                    <div>
              <label className="font-medium max-w-xl text-[#47A7FD]">
                <span className="text-[#D60000]">*</span><i>Upload Your Business Logo</i> 
              </label>
              <div className="bg-[#F3F3F3] w-full h-[73px] border-[1px] rounded-xl">
              <label htmlFor="fileInput-logo" className="cursor-pointer">
                <img src={healthWorkerIcons.dashicons} className='px-[10rem] py-2 items-center' />
                <p className="text-center text-[#47A7FD] text-sm ">
                <span className="text-black">
                {formData.logo && formData.logo.name ? `Selected File: ${formData.logo.name}` : ' Click here to Add your files here '}
                </span>
                </p>
                 </label>
                 <input
              id="fileInput-logo"
              name='logo'
              type="file"
              style={{ display: 'none' }}
              onChange={(e) => handleFileChange(e, 'logo')} // Make sure to provide the 'key'
              ref={fileInputRefs.logo} 
            />
              </div>
              <h1 className="max-w-[15rem] text-[12px] py-2">
                PDF, JPEG & PNG are accepted. File should be within 10mb.
              </h1>
            
            </div>

            <div>
              <label className="font-medium max-w-xl text-[#47A7FD]">
                <span className="text-[#D60000]">*</span><i>Upload Bisiness Registration Document </i> 
              </label>
              <div className="bg-[#F3F3F3] w-full h-[73px] border-[1px] rounded-xl">
              <label htmlFor="fileInput-cac" className="cursor-pointer">
                <img src={healthWorkerIcons.dashicons} className='px-[10rem] py-2 items-center' />
                <p className="text-center text-[#47A7FD] text-sm ">
                <span className="text-black">
                {formData.cac && formData.cac.name ? `Selected File: ${formData.cac.name}` : ' Click here to Add your files here '}
                </span>
                </p>
                 </label>
                 <input
                  id="fileInput-cac"
                  name='cac'
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(e) => handleFileChange(e, 'cac')} // Make sure to provide the 'key'
                  ref={fileInputRefs.cac} 
                />
              </div>
              <h1 className="max-w-[15rem] text-[12px] py-2">
                PDF, JPEG & PNG are accepted. File should be within 10mb.
              </h1>
            
            </div>
                
          
                <div>
                        <label className="font-medium max-w-xl">
                          Bank
                        </label>
                        <FormField
                                type="text"
                                name="bank_name"
                                placeholder='Enter Your Bank Account '
                                 onChange={handleChange}
                                className="w-full mb-4 mt-2 bg-[#F3F3F3] px-4  py-2 text-gray-500  outline-none border focus:border-gray-600 shadow-sm rounded-lg"
                            />
                    </div>
                    <div>
                        <label className="font-medium max-w-xl flex">
                          Account Number
                          <Tooltips message="This is your account number where all your earnings on MyMedicalBank platform will be remitted into.">
                          <div className="relative inline-block">
                            <img src={incomeImg.informationIcon} className="ml-2  h-6 w-6 text-blue-500" alt="Tooltip" />
                          </div>
                        </Tooltips>
                            
                        </label>
                        <FormField
                                type="text"
                                name="account_number"
                                placeholder='Enter Your Account Number '
                                 onChange={handleChange}
                                className="w-full mb-4 mt-2 bg-[#F3F3F3] px-4  py-2 text-gray-500  outline-none border focus:border-gray-600 shadow-sm rounded-lg"
                            />
                    </div>
                    <div>
                        <label className="font-medium max-w-xl">
                         Account Name
                        </label>
                        <FormField
                                type="text"
                                name="account_name"
                                placeholder='Enter Your Account Name '
                                 onChange={handleChange}
                                className="w-full mb-4 mt-2 bg-[#F3F3F3] px-4  py-2 text-gray-500  outline-none border focus:border-gray-600 shadow-sm rounded-lg"
                            />
                    </div>
             
            
               

                 {loading ?
         <button type="submit"  className="text-white w-[20rem] bg-bgbutton hover:bg-bgbutton focus:ring-4 
         focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2.5 text-center
          dark:bg-bgbutton-600 dark:hover:bg-bgbutton-700 dark:focus:ring-bgbutton-800
            " disabled={loading}>  
                 <svg aria-hidden="true" className="inline w-8 h-8  text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                 <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                 <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                 </svg> 
           </button> :
            <button  type="submit" onClick={handleSubmit}  className="text-white w-[20rem] bg-bgbutton hover:bg-bgbutton focus:ring-4 
            focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2.5 text-center
             dark:bg-bgbutton-600 dark:hover:bg-bgbutton-700 dark:focus:ring-bgbutton-800">Submit
             </button>
             
          }
               
              
                    </div>
                );
            
            default:
                return null;
        }
    };

  return (
    <div className='bg-white w-[926px] h-auto rounded-[20px]'>
    <main className="py-8">
        <div className='flex'>
            <div className='w-1/2'>
                <div className="max-w-screen-xl mx-auto px-4 text-gray-600 md:px-8">
                    <div className="max-w-lg  space-y-3 sm:text-center">
                    <p className="text-[#47A7FD] text-sm text-start font-semibold sm:text-[16px] leading-[20px] font-Poppins">
               Business Information
                    </p>
                </div>
            
            <div className="mt-4 max-w-lg ">
                <form
                    onSubmit={(e) => e.preventDefault()}
                    className="space-y-5">
                         {error && <Error message={error}>{error}</Error>}

                

                    {renderStep()}
                    <div className="flex justify-between">
                    {step > 1 && (
                                    <button
                                        type="button"
                                        className="mx-[40rem] text-blue-700 hover:text-white border border-blue-700 
                                        hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg 
                                        text-sm px-5 py-2.5 text-center mr-2 mb-2 mt-8 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white
                                        dark:hover:bg-blue-500 dark:focus:ring-blue-800"
                                        onClick={handlePrevClick}
                                    >
                                        Previous
                                    </button>
                                )}

                                  {step < 2 ? (
                                    <button
                                        type="button"
                                        className="mx-[40rem] text-blue-700 hover:text-white border border-blue-700 
                                        hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg 
                                        text-sm px-5 py-2.5 text-center mr-2 mb-2 mt-8 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white
                                        dark:hover:bg-blue-500 dark:focus:ring-blue-800"
                                        onClick={handleNextClick}
                                    >
                                        Next
                                    </button>
                                ) : (
                                    null
                                )}
                </div>
                </form>
            </div>
        </div>
        </div>
        
       
        </div>
    </main>

    </div>
  )
}

export default ProfileForm;